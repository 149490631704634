<template>
  <div>
    <v-card
      flat
      min-height="100vh"
      class="d-flex align-center"
      style="z-index: 1;">
      <v-card-text>
        <div
          class="text-h4 text-center font-weight-bold mb-2"
          :style="`color: ${set_color};`">
          Amanata
        </div>

        <div
          class="body-1 text-center">
          Sistem Produk HRIS Amanata
        </div>

        <div
          class="d-flex justify-center ma-6">
          <v-progress-linear
            indeterminate
            height="8"
            rounded
            class="loader"
            :color="set_color"
            style="width: 200px;">
          </v-progress-linear>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  export default {
    computed: {
      set_color () {
        return this.$store.state.header.config.color
      }
    }
  }
</script>