<template>
  <div>
    <v-card
      flat
      min-height="25vh"
      color="white"
      style="border-radius: 0px;">
      <v-container>
        <v-row 
          justify="center"
          class="mt-2">
          <v-col 
            cols="12" 
            sm="1"
            class="py-0">
            <nuxt-link 
              to="/">
              <v-img 
                contain 
                width="75"
                height="75"
                :src="require('@/assets/logo/favicon.png')">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular 
                      indeterminate 
                      :color="set_color">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </nuxt-link>
          </v-col>

          <v-col 
            cols="12" 
            sm="2">
            <div 
              class="body-1 font-weight-bold mb-5">
              Amanata
            </div>

            <div 
              v-for="(item, index) in site" 
              :key="index">
              <a 
                :href="item.link">
                <div 
                  class="body-2 black--text mb-2" 
                  style="opacity: 60%;">
                  {{ item.text }}
                </div>
              </a>
            </div>
          </v-col>

          <v-col 
            cols="12" 
            sm="2">
            <div 
              class="body-1 font-weight-bold mb-5">
              Informasi
            </div>

            <div 
              v-for="(item, index) in info" 
              :key="index">
              <nuxt-link :to="item.link">
                <div 
                  class="body-2 black--text mb-2" 
                  style="opacity: 60%;">
                  {{ item.text }}
                </div>
              </nuxt-link>
            </div>
          </v-col>

          <v-col 
            cols="12" 
            sm="3">
            <div 
              class="body-1 font-weight-bold mb-5">
              Kontak Kami
            </div>

            <div 
              v-for="(item, index) in contact" 
              :key="index">
              <nuxt-link :to="item.link">
                <div 
                  class="body-2 black--text mb-2" 
                  style="opacity: 60%;">
                  {{ item.text }}
                </div>
              </nuxt-link>
            </div>
          </v-col>

          <v-col 
            cols="12" 
            sm="4">
            <div class="body-1 font-weight-bold mb-5">
              Berlangganan
            </div>

            <v-row>
              <v-col 
                cols="9" 
                class="pt-0 pb-0 pr-0">
                <v-text-field
                  dense
                  rounded
                  prepend-inner-icon="mdi-email-outline"
                  placeholder="Alamat Email Anda"
                  :color="set_color"
                  single-line 
                  hide-details="auto"
                  filled>
                </v-text-field>
              </v-col>

              <v-col 
                cols="1"
                class="py-0 pl-0">
                <v-btn
                  rounded
                  elevation="0"
                  :color="set_color"
                  style="position: relative; right: 30px; height: 40px;">
                  <v-icon color="white">mdi-email-fast-outline</v-icon>
                </v-btn>
              </v-col>
            </v-row>

            <v-avatar 
              v-for="(item, index) in sosmed"
              :key="index"
              class="mt-4 mr-3"
              size="35">
              <a 
                :href="item.link" 
                target="_blank"
                class="d-flex align-center justify-center">
                <v-img
                  contain
                  width="35"
                  height="35"
                  class="ma-auto"
                  :src="item.icon">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular 
                        size="20"
                        indeterminate 
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </a>
            </v-avatar>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <div 
      class="white--text"
      :style="`background: ${set_color};`">
      <v-container>
        <v-row 
          justify="center">
          <!-- <v-col 
            cols="6" 
            class="py-0 px-3">
            <div 
              class="text-left">
              Copyright@{{ new Date().getFullYear() }} <strong v-if="rps === 'lg'">amanata.co</strong>
            </div>
          </v-col> -->

          <v-col 
            cols="12" 
            class="py-0 px-3">
            <div 
              class="text-center d-flex align-center justify-center">
              Powered by PT Solusi Digital Manunggal
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        site: [
          {
            text: 'Tentang Kami',
            link: '#tentang'
          },
          {
            text: 'Kemitraan',
            link: '#mitra'
          },
          {
            text: 'Layanan',
            link: '#'
          },
          {
            text: 'Berita',
            link: '#'
          }
        ],
        info: [
          {
            text: 'Syarat & Ketentuan',
            link: '#'
          },
          {
            text: 'Kebijakan Privasi',
            link: '#'
          },
          {
            text: 'Karir',
            link: '#'
          },
          {
            text: 'FAQ',
            link: '#'
          }
        ],
        contact: [
          {
            text: 'Alamat: Jl. Ngadinegaran Blok MJ III No 144 Mantrijeron, Kota Yogyakarta, Daerah Istimewa Yogyakarta, 55143',
            link: '#'
          },
          {
            text: 'Email: info@amanata.co',
            link: '#'
          },
          {
            text: 'Telp / Wa: +62811256599',
            link: '#'
          }
        ],
        sosmed: [
          {
            icon: require("@/assets/sosmed/instagram.png"),
            link: '#'
          },
          {
            icon: require("@/assets/sosmed/youtube.png"),
            link: '#'
          },
          {
            icon: require("@/assets/sosmed/linkedin.png"),
            link: '#'
          }
        ]
      }
    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      company () {
        return this.$store.state.header.config.company
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r+35}, ${g+37}, ${b}, 0.97)`
      }
    }
  }
</script>
