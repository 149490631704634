<template>
  <div>
    <v-app-bar v-if="rps === 'lg'"
      app
      fixed
      elevation="0"
      class="header">
      <v-container>
        <div
          class="header_contact mt-16"
          style="background: #00000040; border-radius: 0px 0px 20px 20px;">
          <v-row
            align="center">
            <v-col 
              cols="12">
              <div
                class="d-flex align-center">
                <div>
                  <div
                    class="d-flex align-center pl-4">
                    <v-img 
                      contain
                      width="20"
                      height="20"
                      alt="amanata" 
                      :src="require('@/assets/sosmed/phone.svg')"
                      class="ma-auto">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular 
                            size="20"
                            indeterminate 
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>

                    <div
                      class="body-2 white--text ml-2"
                      style="opacity: 70%;">
                      +62811256599
                    </div>

                    <div
                      class="mx-4">
                      <v-divider
                        vertical
                        dark
                        style="height: 26px; margin-top: auto;  margin-bottom: auto;" />
                    </div>

                    <v-img 
                      contain
                      width="20"
                      height="20"
                      alt="amanata" 
                      :src="require('@/assets/sosmed/email.svg')"
                      class="ma-auto">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular 
                            size="20"
                            indeterminate 
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>

                    <div
                      class="body-1 white--text ml-2"
                      style="opacity: 70%;">
                      info@amanata.co
                    </div>
                  </div>
                </div>

                <v-spacer />
                
                <div
                  class="pr-2">
                  <v-btn
                    v-for="(item, index) in sosmed"
                    :key="index"
                    color="#00000040"
                    width="28"
                    min-width="28"
                    height="28"
                    class="mx-2 pa-0"
                    style="border-radius: 10px;">
                    <v-icon
                      size="20"
                      color="white"
                      style="opacity: 40%;">
                      {{ item.icon }}
                    </v-icon>
                  </v-btn>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
        
        <v-row>
          <v-col 
            class="d-flex align-center">
            <nuxt-link 
              to="/">
              <div>
                <v-img
                  contain
                  width="150"
                  height="auto"
                  :src="require('@/assets/logo/logo_white.png')">
                </v-img>
              </div>
            </nuxt-link>

            <v-spacer/>

            <nuxt-link 
              v-for="(item, index) in menu"
              :key="index"
              :to="item.link">
              <v-card-title 
                class="subtitle-1 d-flex justify-center"
                style="position: relative;">
                <span 
                  class="white--text">
                  {{ item.text }}
                </span>

                <v-divider v-if="item.link === $route.fullPath"
                  style="border: 2px solid #FFFFFF;
                        border-radius: 50px;
                        position: absolute;
                        width: 45px;
                        bottom: 10px;" />
              </v-card-title>
            </nuxt-link>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>

    <v-app-bar v-if="rps === 'xs'"
      app
      fixed
      elevation="0"
      class="header_small">
      <v-container
        class="pa-0">
        <div
          class="d-flex align-center">
          <nuxt-link 
            to="/">
            <div 
              class="d-flex align-center">
              <v-img
                contain
                width="140"
                height="auto"
                :src="require('@/assets/logo/logo_white.png')">
              </v-img>
            </div>
          </nuxt-link>

          <v-spacer/>

          <v-menu
            bottom
            left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                icon
                v-bind="attrs"
                v-on="on">
                <v-icon color="white">mdi-menu</v-icon>
              </v-btn>
            </template>

            <v-list
              dense
              class="py-1">
              <v-list-item
                v-for="(item, index) in menu"
                :key="index"
                :to="item.link">
                <v-list-item-title>
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-container>
    </v-app-bar>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        selected: {
          menu: 0
        },
        menu: [
          {
            text: "Beranda",
            link: "/"
          },
          {
            text: "Tentang",
            link: "/#tentang"
          },
          {
            text: "Produk",
            link: "/#produk"
          },
          // {
          //   text: "Adds On",
          //   link: "/#addson"
          // },
          {
            text: "Partner",
            link: "/#partner"
          }
        ],
        sosmed: [
          {
            icon: 'mdi-instagram',
            link: '#'
          },
          {
            icon: 'mdi-youtube',
            link: '#'
          },
          {
            icon: 'mdi-linkedin',
            link: '#'
          }
        ]
      }
    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      company () {
        return this.$store.state.header.config.company
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r+35}, ${g+37}, ${b}, 0.97)`
      }
    },
    mounted () {
      const debounce = (fn) => {
        let frame;
        return (...params) => {
          if (frame) { 
            cancelAnimationFrame(frame)
          }
          frame = requestAnimationFrame(() => {
            fn(...params)
          })
        } 
      };
      const storeScroll = () => {
        document.documentElement.dataset.scroll = window.scrollY
      }
      document.addEventListener('scroll', debounce(storeScroll), { passive: true })

      storeScroll()
    },
    methods: {
      
    }
  }
</script>

<style>
  .header_favicon {
    background-image: url('@/assets/logo/favicon.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-attachment: fixed;

    width: 50px;
    height: 50px;
  }
  
  .header {
    background-color: transparent !important;
    height: 130px !important;
  }

  .header_small {
    background-color: transparent !important;
  }

  html:not([data-scroll="0"]) .header {
    background-color: rgba(117, 80, 225, 1) !important;
    height: 65px !important;
  }

  html:not([data-scroll="0"]) .header_small {
    background-color: rgba(117, 80, 225, 1) !important;
  }

  html:not([data-scroll="0"]) .header_favicon {
    /* background-image: url('@/assets/logo/favicon_white.png'); */
    background-repeat: no-repeat;
    background-size: contain;
    background-attachment: fixed;

    width: 50px;
    height: 50px;
  }

  html:not([data-scroll="0"]) .header_contact {
    display: none;
  }
  
  .v-menu__content {
    box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  }
</style>