<template>
  <div class="fill-height">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-img 
            min-height="100vh"
            alt="amanata"
            class="d-flex align-center"
            gradient="to bottom, rgba(117, 80, 225, .3), rgba(117, 80, 225, .3)"
            :src="require('@/assets/background/bg_3.jpg')">
            <v-container>
              <v-row 
                justify="center" 
                align="center">
                <v-col
                  cols="12"
                  md="7">
                  <div 
                    class="white--text"
                    :class="rps === 'lg' ? 'text-h2 mb-6' : 'text-h3 mb-10'"
                    style="font-weight: 500; line-height: 1.2;">
                    Grow your People, Grow your Business
                  </div>

                  <div v-if="rps !== 'xs' || play_youtube < 1"
                    class="line-text-first white--text"
                    :class="rps === 'lg' ? 'text-h4 mb-16' : 'text-h5 mb-8'"
                    style="font-weight: 500;">
                    Kami percaya bahwa tim yang solid, produktif, dan kompeten akan membawa value bagi perusahaan.
                  </div>

                  <iframe v-if="rps === 'xs' && play_youtube > 0"
                    width="100%"
                    height="250"
                    :src="`https://www.youtube.com/embed/mGVtudcy-uU?autoplay=${play_youtube}&mute=0&controls=0`" 
                    title="Amanata.co" 
                    frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    allowfullscreen
                    class="mb-8"
                    style="border-radius: 20px;">
                  </iframe>

                  <div
                    :class="play_youtube ? '' : 'mt-12'"
                    class="d-flex align-center">
                    <v-btn
                      href="https://api.whatsapp.com/send/?phone=62811256599&text&type=phone_number&app_absent=0"
                      target="_blank"
                      rounded
                      :x-large="rps === 'lg' ? true : false"
                      :large="rps === 'lg' ? false : true"
                      elevation="0"
                      :color="set_color"
                      class="text-capitalize font-weight-bold white--text"
                      :class="rps === 'lg' ? 'title' : 'body-1'">
                      <v-icon size="28" class="mr-1">mdi-whatsapp</v-icon>

                      Hubungi Kami Sekarang !
                    </v-btn>

                    <v-spacer />
                    
                    <v-btn v-if="rps === 'xs' && play_youtube < 1"
                      @click="play_youtube = 1"
                      fab
                      width="50"
                      height="50"
                      elevation="0"
                      color="transparent">
                      <v-icon color="white" style="font-size: 50px;">mdi-motion-play</v-icon>
                    </v-btn>

                    <v-btn v-if="rps === 'xs' && play_youtube > 0"
                      @click="play_youtube = 0"
                      fab
                      width="50"
                      height="50"
                      elevation="0"
                      color="transparent">
                      <v-icon color="white" style="font-size: 50px;">mdi-stop-circle-outline</v-icon>
                    </v-btn>
                  </div>
                </v-col>

                <v-col v-if="rps === 'lg'"
                  cols="12"
                  md="5"
                  class="text-center">
                  <v-btn v-if="play_youtube < 1"
                    @click="play_youtube = 1"
                    fab
                    width="125"
                    height="125"
                    elevation="0"
                    color="transparent">
                    <v-icon color="white" style="font-size: 125px;">mdi-motion-play</v-icon>
                  </v-btn>

                  <div v-if="play_youtube > 0"
                    class="image_container">
                    <v-img 
                      contain
                      width="700"
                      height="auto"
                      alt="amanata"
                      :src="require('@/assets/background/bg_youtube.png')"
                      class="preview_image">
                    </v-img>
                    
                    <div class="containers">
                      <iframe
                        class="iframe_example" 
                        :src="`https://www.youtube.com/embed/mGVtudcy-uU?autoplay=${play_youtube}&mute=0&controls=0`"
                        title="Amanata.co" 
                        frameborder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                        allowfullscreen>
                      </iframe>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-container>

            <div 
              class="text-center"
              style="position: absolute; bottom: 20px; left: 0; right: 0;">
              <v-btn
                fab
                elevation="0"
                color="transparent"
                style="opacity: 60%;"
                @click="$vuetify.goTo('#tentang', {duration: 500, offset: 0})">
                <v-icon color="white" style="font-size: 40px;">mdi-arrow-down-bold-hexagon-outline</v-icon>
              </v-btn>
            </div>
          </v-img>
        </section>

        <section
          id="tentang"
          class="pt-12">
          <v-card
            flat
            color="transparent">
            <v-card-text>
              <div
                class="text-center">
                <div
                  class="black--text"
                  :class="rps === 'lg' ? 'text-h4' : 'text-h5'">
                  Tentang Kami
                </div>

                <div
                  class="text-h3 my-4"
                  :class="rps === 'lg' ? 'text-h3' : 'text-h4'"
                  :style="`color: ${set_color};`">
                  PT Solusi Digital Manunggal
                </div>

                <div
                  class="line-text-second"
                  :class="rps === 'lg' ? 'title' : 'body-1'"
                  style="font-weight: 400;">
                  <div>
                    PT Solusi Digital Manunggal adalah perusahaan yang berfokus pada penyediaan solusi sumber daya manusia.
                  </div>

                  <div>
                    Dengan pengalaman yang luas dalam industri teknologi, amanata memiliki komitmen untuk membantu organisasi
                  </div>

                  <div>
                    dalam mengelola aspek-aspek kunci terkait sumber daya manusia dengan lebih efektif dan efisien.
                  </div>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </section>

        <section
          id="visimisi"
          class="pt-12">
          <v-container>
            <v-card
              flat
              style="border-radius: 30px; box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;">
              <v-img 
                contain
                min-height="200px"
                alt="amanata"
                gradient="to bottom, rgba(117, 80, 225, .3), rgba(117, 80, 225, .3)"
                :src="rps === 'lg' ? require('@/assets/background/bg_section_3.png') : ''">
                <v-card
                  flat
                  color="transparent">
                  <v-card-text
                    :class="rps === 'lg' ? 'mt-12' : 'mt-4'">
                    <div
                      class="text-center">
                      <div
                        :class="rps === 'lg' ? 'text-h4 white--text' : 'text-h5'"
                        :style="rps === 'lg' ? '' : `color: ${set_color};`">
                        Visi & Misi
                      </div>

                      <div
                        class="line-text-second mt-4"
                        :class="rps === 'lg' ? 'title' : 'body-1'"
                        style="font-weight: 400;"
                        :style="rps === 'lg' ? 'color: white;' : `color: ${set_color};`">
                        <div>
                          Menjadi pilihan utama dan mitra yang dipercaya dalam penyediaan solusi teknologi
                        </div>

                        <div>
                          sumber daya manusia yang inovatif, efektif, dan berkelanjutan.
                        </div>
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
                
                <div
                  class="d-flex justify-center mt-2"
                  :class="rps === 'lg' ? 'mb-12' : ''">
                  <v-divider 
                    :style="rps === 'lg' ? 'border-top: 10px solid #FFFFFF;' : `border-top: 10px solid ${set_color};`"
                    style="max-width: 125px; border-radius: 50px;"/>
                </div>

                <v-row 
                  class="pa-4"
                  justify="center" 
                  align="center">
                  <v-col
                    cols="12"
                    md="6"
                    :class="rps === 'lg' ? '' : 'pb-0'">
                    <v-card
                      flat
                      color="transparent">
                      <v-card-text>
                        <v-list
                          fla
                          color="transparent"
                          :class="rps === 'lg' ? '' : 'py-0'">
                          <v-list-item
                            v-for="(item, index) in misi.left"
                            :key="index"
                            :class="index < misi.left.length - 1 ? 'mb-8' : ''"
                            style="border: 2px dashed #FFFFFF; border-radius: 10px; background: #FFFFFF20;">
                            <v-list-item-avatar
                              color="white">
                              <v-icon color="green">mdi-check-bold</v-icon>
                            </v-list-item-avatar>
                            
                            <v-list-item-content>
                              <div
                                class="body-1"
                                :style="rps === 'lg' ? 'color: white;' : `color: ${set_color};`">
                                {{ item }}
                              </div>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-card-text>
                    </v-card>
                  </v-col>

                  <v-col
                    cols="12"
                    md="6"
                    :class="rps === 'lg' ? '' : 'pt-0'">
                    <v-card
                      flat
                      color="transparent">
                      <v-card-text>
                        <v-list
                          fla
                          color="transparent"
                          :class="rps === 'lg' ? '' : 'py-0'">
                          <v-list-item
                            v-for="(item, index) in misi.right"
                            :key="index"
                            :class="index < misi.right.length - 1 ? 'mb-8' : ''"
                            style="border: 2px dashed #FFFFFF; border-radius: 10px; background: #FFFFFF20;">
                            <v-list-item-avatar
                              color="white">
                              <v-icon color="green">mdi-check-bold</v-icon>
                            </v-list-item-avatar>
                            
                            <v-list-item-content>
                              <div
                                class="body-1"
                                :style="rps === 'lg' ? 'color: white;' : `color: ${set_color};`">
                                {{ item }}
                              </div>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-img>
            </v-card>
          </v-container>
        </section>

        <section
          id="produk"
          :class="rps === 'lg' ? 'pt-12' : ''">
          <v-container>
            <v-row>
              <v-col>
                <v-card
                  flat
                  color="transparent">
                  <v-card-text>
                    <div
                      class="text-center">
                      <div
                        class="black--text"
                        :class="rps === 'lg' ? 'text-h4' : 'text-h5'">
                        Produk <span :style="`color: ${set_color};`">Amanata</span>
                      </div>
                    </div>
                  </v-card-text>
                </v-card>

                <div
                  class="d-flex justify-center mt-2 mb-12">
                  <v-divider 
                    :style="`max-width: 125px; border-top: 10px solid ${set_color}; border-radius: 50px;`"/>
                </div>

                <div v-if="rps === 'lg'"
                  class="d-flex align-center"
                  style="position: relative;">
                  <v-card
                    flat
                    class="pa-4"
                    :color="set_color_rgb_soft"
                    style="border-radius: 50px; border: 4px solid #FFFFFF;">
                    <div
                      v-for="(item, index) in produk" 
                      :key="index"
                      :class="index > 0 ? 'pt-6' : ''">
                      <v-row
                        align="center"
                        class="px-6">
                        <v-col
                          cols="8"
                          :class="index > 0 ? '' : 'pt-0'">
                          <div
                            class="title black--text mb-1">
                            {{ item.title }}
                          </div>

                          <div
                            class="body-1 line-text-first"
                            style="opacity: 70%;">
                            {{ item.desc }}
                          </div>
                        </v-col>

                        <v-col
                          cols="4"
                          class="text-right"
                          :class="index > 0 ? '' : 'pt-0'">
                          <v-avatar
                            tile
                            size="100">
                            <v-img 
                              contain
                              alt="amanata" 
                              :src="require('@/assets/logo/favicon.png')"
                              class="ma-auto">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular 
                                    size="20"
                                    indeterminate 
                                    :color="set_color">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </v-avatar>
                        </v-col>
                      </v-row>

                      <v-card
                        flat
                        :color="set_color"
                        class="pa-6 px-8"
                        style="border-radius: 50px; box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;">
                        <v-row
                          style="1075px;">
                          <v-col
                            v-for="(item_sub, index_sub) in item.data"
                            :key="index_sub"
                            cols="6">
                            <div>
                              <v-card
                                flat
                                width="75"
                                height="75"
                                color="#FFFFFF40"
                                class="d-flex align-center justify-center"
                                style="border-radius: 20px !important; border: 4px solid #FFFFFF60;">
                                <div>
                                  <v-img 
                                    contain
                                    width="50"
                                    height="50"
                                    alt="amanata" 
                                    :src="item_sub.icon"
                                    class="ma-auto">
                                    <template v-slot:placeholder>
                                      <v-row
                                        class="fill-height"
                                        align="center"
                                        justify="center">
                                        <v-progress-circular 
                                          indeterminate 
                                          :color="set_color">
                                        </v-progress-circular>
                                      </v-row>
                                    </template>
                                  </v-img>
                                </div>
                              </v-card>
                            </div>
                            
                            <div
                              class="white--text">
                              <div
                                class="body-1 font-weight-bold mt-4 mb-1">
                                {{ item_sub.title }}
                              </div>

                              <div
                                class="body-1 font-italic mb-4">
                                {{ item_sub.text }}
                              </div>

                              <div
                                class="body-2 line-text-first">
                                {{ item_sub.desc }}
                              </div>
                            </div>
                          </v-col>
                        </v-row>
                      </v-card>
                    </div>
                  </v-card>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </section>

        <!-- <section
          id="legalitas"
          class="py-12">
          <v-card
            flat
            color="transparent">
            <v-card-text>
              <div
                class="text-center">
                <div
                  class="black--text mb-4"
                  :class="rps === 'lg' ? 'text-h4' : 'text-h5'">
                  Legalitas <span :style="`color: ${set_color}`">Amanata</span>
                </div>

                <div
                  class="line-text-second"
                  :class="rps === 'lg' ? 'title' : 'body-1'"
                  style="font-weight: 400;">
                  <div>
                    Nomor Induk Berusaha 0902230039308, Tanggal 07 Februari 2023
                  </div>

                  <div>
                    Akta Notaris Tunjung Widhi Wasesa Suwadji, SH, M.Kn. No. 145
                  </div>

                  <div>
                    SK Menteri Hukum dan Hak Asasi Manusia Republik Indonesia
                  </div>

                  <div>
                    Nomor AHU 01210.AH.02.01 Tahun 2016, Tanggal 18 November 2016.
                  </div>
                </div>
              </div>
              
              <div
                class="d-flex justify-center mt-7 mb-6">
                <v-divider 
                  :style="`max-width: 125px; border-top: 10px solid ${set_color}; border-radius: 50px;`"/>
              </div>
            </v-card-text>
          </v-card>
          
          <v-container>
            <v-row
              align="center"
              justify="center">
              <v-col
                cols="12"
                :md="rps === 'lg' ? 8 : 12"
                class="text-center">
                <v-card
                  flat
                  style="border-radius: 30px; box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;">
                  <v-img 
                    contain
                    alt="amanata"
                    :src="require('@/assets/image/struktur.jpeg')"
                    class="ma-auto">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular 
                          indeterminate 
                          :color="set_color">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </section> -->

        <!-- <section 
          class="parallax">
          <v-container>
            <v-row 
              align="center" 
              justify="center" 
              :style="rps === 'lg' ? 'height: 500px;' : 'height: 600px;'">
              <v-col>
                <div 
                  class="white--text text-center">
                  <div
                    class="mb-6"
                    :class="rps === 'lg' ? 'text-h4' : 'text-h5'">
                    Terima Kasih Telah Mengunjungi Layanan Kami
                  </div>
                </div>

                <v-row
                  justify="center">
                  <v-col
                    cols="12" 
                    md="12">
                    <div 
                      class="text-center white--text">
                      <div 
                        class="line-text-second mb-12"
                        :class="rps === 'lg' ? 'title' : 'body-1'"
                        style="font-weight: 400;">
                        Amanata berkomitmen untuk memberikan solusi yang dapat menghemat waktu, mengurangi biaya administrasi, dan meningkatkan produktivitas sumber daya manusia, sehingga memungkinkan pengguna untuk fokus pada inti bisnis perusahaan. Dengan tim yang berpengalaman dan berdedikasi, Amanata adalah mitra yang dapat diandalkan dalam mengelola aspek sumber daya manusia untuk perusahaan.
                        Dengan pendekatan yang terintegrasi dan teknologi terkini, Amanata membantu perusahaan mengoptimalkan potensi sumber daya manusia mereka, sehingga dapat mencapai tujuan bisnis dengan lebih baik.
                      </div>

                      <v-btn
                        href="https://api.whatsapp.com/send/?phone=62811256599&text&type=phone_number&app_absent=0"
                        target="_blank"
                        rounded
                        :x-large="rps === 'lg' ? true : false"
                        :large="rps === 'lg' ? false : true"
                        elevation="0"
                        :color="set_color"
                        class="text-capitalize font-weight-bold white--text"
                        :class="rps === 'lg' ? 'title' : 'body-1'">
                        <v-icon size="28" class="mr-1">mdi-whatsapp</v-icon>

                        Hubungi Kami Sekarang !
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </section> -->

        <section
          id="partner"
          class="py-16">
          <v-card
            flat
            color="transparent">
            <v-card-text
              :class="rps === 'lg' ? '' : 'px-0'"
              :style="rps === 'lg' ? 'padding: 0px 260px;' : ''">
              <div
                class="text-center">
                <div
                  class="black--text"
                  :class="rps === 'lg' ? 'text-h4' : 'text-h5'">
                  Mereka percaya dengan <span :style="`color: ${set_color};`">Amanata</span>
                </div>

                <div
                  class="d-flex justify-center mt-7 mb-10">
                  <v-divider 
                    :style="`max-width: 125px; border-top: 10px solid ${set_color}; border-radius: 50px;`"/>
                </div>
              </div>
              
              <v-row v-if="rps === 'lg'"
                justify="center">
                <v-col
                  v-for="(item, index) in partner"
                  :key="index"
                  cols="12"
                  md="2">
                  <div
                    class="text-center">
                    <v-card
                      flat
                      color="transparent">
                      <v-img 
                        contain
                        width="175"
                        height="175"
                        alt="amanata"
                        :src="item"
                        class="ma-auto"
                        style="border-radius: 30px; box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular 
                              indeterminate 
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-card>
                  </div>
                </v-col>
              </v-row>
              
              <v-card v-if="rps === 'xs'"
                flat
                class="py-4"
                color="transparent">
                <no-ssr>
                  <swiper 
                    ref="swipervalue"
                    class="swiper"
                    :options="swiperOption">
                    <swiper-slide 
                      v-for="(item, index) in partner" 
                      :key="index" 
                      style="width: auto;">
                      <div
                        class="text-center">
                        <v-card
                          flat
                          color="transparent"
                          :class="index < 1 ? 'mx-5' : 'mr-5'">
                          <v-img 
                            contain
                            width="175"
                            height="175"
                            alt="amanata"
                            :src="item"
                            class="ma-auto"
                            style="border-radius: 30px; box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular 
                                  indeterminate 
                                  :color="set_color">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-card>
                      </div>
                    </swiper-slide>
                  </swiper>
                </no-ssr>
              </v-card>
            </v-card-text>
          </v-card>
        </section>

        <section
          class="py-16"
          :style="rps === 'lg' ? '' : 'margin-bottom: 350px;'">
          <v-card
            flat
            color="transparent"
            style="position: absolute; width: 100%;">
            <v-container>
              <v-row
                align="center">
                <v-col>
                  <v-card
                    flat
                    class="bg_group pa-2"
                    min-height="200"
                    :color="set_color"
                    style="border-radius: 30px;">
                    <v-row>
                      <v-col
                        cols="12"
                        md="12">
                        <div
                          :class="rps === 'lg' ? 'px-6' : 'px-4'">
                          <div
                            class="white--text mb-4"
                            :class="rps === 'lg' ? 'text-h4' : 'text-h5'">
                            Komitmen Amanata
                          </div>

                          <div
                            class="line-text-second white--text"
                            :class="rps === 'lg' ? 'title' : 'body-1'"
                            style="font-weight: 400;">
                            Amanata berkomitmen untuk memberikan solusi yang dapat menghemat waktu, mengurangi biaya administrasi, dan meningkatkan produktivitas sumber daya manusia, sehingga memungkinkan pengguna untuk fokus pada inti bisnis perusahaan. Dengan tim yang berpengalaman dan berdedikasi, Amanata adalah mitra yang dapat diandalkan dalam mengelola aspek sumber daya manusia untuk perusahaan.
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </section>

        <section
          class="bg_footer">
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: false,
    data () {
      return {
        play_youtube: 0,

        swiperOption: {
          slidesPerView: 'auto'
        },

        produk: [
          {
            title: 'System Development',
            desc: 'Untuk membantu kebutuhan Anda dalam mempercepat pertumbuhan dan keuntungan bisnis, Amanata memberikan pengalaman terbaik dalam pembuatan software yang sudah teruji dan terintegrasi dengan teknologi terbaru.',
            data: [
              {
                title: 'HRIS Application',
                text: '(Human Resource Information System)',
                icon: require('@/assets/icon/dev_1.png'),
                desc: 'Dengan HRIS Application dari Amanata, perusahaan dapat mengotomatiskan banyak proses  administratif yang terkait dengan manajemen sumber daya manusia, sehingga memungkinkan fokus lebih pada pengembangan strategi dan peningkatan pengalaman karyawan.'
              },
              {
                title: 'HMS Application',
                text: '(Healthcare Management System)',
                icon: require('@/assets/icon/dev_2.png'),
                desc: 'Sistem Informasi Manajemen Fasilitas Pelayanan Kesehatan (SIM RS) yang telah menerapkan standar Fast Healthcare Interoperability Resources (FHIR) baik Modul maupun standar data SATUSEHAT dari KEMENKES RI, dimana standar ini akan segera diterapkan ke seluruh Indonesia untuk saling terintegrasi.'
              },
              {
                title: 'ERP Application',
                text: '(Enterprise Resource Planning)',
                icon: require('@/assets/icon/dev_3.png'),
                desc: 'Dengan sistem ERP, perusahaan dapat mengotomatiskan sebagian besar proses bisnis, memfasilitasi aliran informasi yang lancar antar departemen dan memberikan pemahaman yang lebih baik tentang kinerja bisnis secara keseluruhan melalui berbagai fitur pelaporan dan analisis. Dengan menerapkan ERP, perusahaan dapat meningkatkan efisiensi operasional, meningkatkan visibilitas dan pengawasan atas seluruh operasi, serta meningkatkan kemampuan untuk merespons perubahan pasar dengan lebih cepat dan tepat.'
              },
              {
                title: 'Kustomisasi Aplikasi',
                text: '(Sesuai dengan Keinginan Anda)',
                icon: require('@/assets/icon/dev_4.png'),
                desc: 'Penyesuaian aplikasi perangkat lunak yang sudah ada sesuai dengan kebutuhan atau preferensi spesifik bisnis atau organisasi, seperti; penambahan, penghapusan, atau perubahan fitur-fitur dalam aplikasi untuk membuatnya lebih sesuai dengan kebutuhan pengguna dan juga dapat melibatkan integrasi dengan sistem atau infrastruktur yang sudah ada di organisasi. Tujuan dari kustomisasi aplikasi adalah membantu organisasi untuk mendapatkan keunggulan kompetitif dengan memiliki solusi yang unik dan disesuaikan dengan kebutuhan bisnis Anda.'
              }
            ]
          },
          {
            title: 'White Label Application',
            desc: 'Anda ingin menjadi pebisnis di bidang teknologi yang memiliki brand sendiri tetapi bingung memikirkan biaya produksi dan perawatan?',
            data: [
              {
                title: 'White Label Application',
                text: '',
                icon: require('@/assets/icon/white_1.png'),
                desc: 'Disinilah peran Amanata sebagai penyedia jasa pengerjaan produk aplikasi yang dapat disesuaikan dengan ide bisnis Anda. Beraneka macam sample aplikasi terbaik telah kami siapkan untuk menentukan pilihan Anda. Selain dapat meminimalisir gagalnya produk, berpartner dengan Amanata dapat menghemat biaya produksi, perawatan dan mengefisiensi waktu Anda.'
              },
              {
                title: 'IT Master Plan',
                text: '',
                icon: require('@/assets/icon/white_2.png'),
                desc: 'IT Master Plan berfungsi sebagai panduan strategis bagi organisasi dalam mengelola investasi dan pengembangan teknologi informasi, serta memastikan bahwa Teknologi diintegrasikan secara efektif dengan strategi bisnis yang lebih luas. Dokumen ini mencakup berbagai aspek, seperti; infrastruktur TI, aplikasi perangkat lunak, keamanan informasi, pengelolaan data, serta sumber daya manusia dan kebijakan yang terkait dengan teknologi dan dapat disesuaikan dengan kebutuhan dan karakteristik unik dari setiap organisasi.'
              },
              {
                title: 'Change Management',
                text: '',
                icon: require('@/assets/icon/white_3.png'),
                desc: 'Layanan yang membantu organisasi dalam mengelola perubahan dengan efektif. Layanan ini mencakup berbagai pendekatan, strategi, dan praktik yang digunakan untuk merencanakan, mengimplementasikan, dan mengawasi perubahan organisasional. Melalui Change Management, organisasi dapat mengurangi resistensi terhadap perubahan, memastikan kesinambungan operasional, dan mencapai tujuan strategis yang diinginkan melalui perubahan yang dikelola dengan baik.'
              }
            ]
          },
          {
            title: 'Big Data',
            desc: 'layanan untuk membantu organisasi dalam menganalisis, mengelola, dan mendapatkan wawasan berharga dari volume besar data yang dihasilkan oleh organisasi.',
            data: [
              {
                title: 'Big Data',
                text: '',
                icon: require('@/assets/icon/big_1.png'),
                desc: 'Tujuan dari Big Data Analytics adalah untuk membantu organisasi mengambil keputusan yang lebih baik dan mendapatkan keunggulan kompetitif dengan memanfaatkan potensi data besar dan memungkinkan organisasi untuk mengidentifikasi peluang baru, mengoptimalkan operasi, meningkatkan pengalaman pelanggan, dan merencanakan strategi bisnis yang lebih efektif berdasarkan analisis data yang mendalam.'
              },
              {
                title: 'Web Support',
                text: '',
                icon: require('@/assets/icon/big_1.png'),
                desc: 'Web support memiliki peran penting dalam memaksimalkan potensi bisnis perusahaan Anda. Web support adalah sarana promosi yang efektif dan efisien untuk menampilkan informasi secara lengkap mengenai bisnis dan produk perusahaan Anda. Bersama Amanata, Anda dapat mewujudkan hasil terbaik pada kebutuhan sistem bisnis Anda.'
              },
              {
                title: 'Customer Support',
                text: '',
                icon: require('@/assets/icon/big_1.png'),
                desc: 'Terdapat banyak keuntungan yang akan Anda dapatkan selama menggunakan jasa kami. Amanata berkomitmen untuk memberikan pelayanan secara maksimal, termasuk Service Level Agreement (SLA) sebesar 99%, pencadangan data (Backup Data), manajemen proyek (Project Management), pendampingan teknis (Technical Assistance), garansi sepanjang waktu, dan dukungan pelanggan (Customer Support), baik secara daring maupun dengan kunjungan langsung ke lokasi Anda. Kami selalu siap membantu Anda.'
              }
            ]
          }
        ],

        misi: {
          left: [
            'Menyediakan layanan dan produk teknologi informasi dengan performa terbaik, inovatif, dan terintegrasi secara profesional dan berstandar internasional.',
            'Menjalin hubungan kerjasama yang baik dengan mitra dan klien melalui teknologi informasi maupun sebagai partner atau mitra bisnis.'
          ],
          right: [
            'Berperan serta dalam pembangunan perekonomian Indonesia melalui layanan teknologi informasi.',
            'Menyediakan tempat dan budaya kerja yang kondusif, nyaman, menjunjung tinggi semangat kreativitas, inovatif, profesionalitas serta menciptakan sumber daya manusia yang kompeten dan berstandar internasional.'
          ]
        },

        partner: [
          require('@/assets/partner/1.png'),
          require('@/assets/partner/2.png'),
          require('@/assets/partner/3.png'),
          require('@/assets/partner/4.png'),
          require('@/assets/partner/5.png'),
          require('@/assets/partner/6.png'),
          require('@/assets/partner/7.png'),
          require('@/assets/partner/8.png'),
          require('@/assets/partner/9.png'),
          require('@/assets/partner/10.png'),
          require('@/assets/partner/11.png'),
          require('@/assets/partner/12.png'),
          require('@/assets/partner/13.png'),
          require('@/assets/partner/14.png'),
          require('@/assets/partner/15.png'),
          require('@/assets/partner/16.png'),
          require('@/assets/partner/17.png'),
          require('@/assets/partner/18.png'),
          require('@/assets/partner/19.png'),
          require('@/assets/partner/20.png'),
          require('@/assets/partner/21.png'),
          require('@/assets/partner/22.png'),
          require('@/assets/partner/23.png'),
          require('@/assets/partner/24.png'),
          require('@/assets/partner/25.png'),
          require('@/assets/partner/26.png'),
          require('@/assets/partner/27.png'),
          require('@/assets/partner/28.png'),
          require('@/assets/partner/29.png'),
          require('@/assets/partner/30.png'),
          require('@/assets/partner/31.png'),
          require('@/assets/partner/32.png'),
          require('@/assets/partner/33.png'),
          require('@/assets/partner/34.png'),
          require('@/assets/partner/35.png'),
          require('@/assets/partner/36.png'),
          require('@/assets/partner/37.png'),
          require('@/assets/partner/38.png'),
          require('@/assets/partner/39.png'),
          require('@/assets/partner/40.png'),
          require('@/assets/partner/41.png'),
          require('@/assets/partner/42.png'),
          require('@/assets/partner/43.png'),
          require('@/assets/partner/44.png'),
          require('@/assets/partner/45.png'),
          require('@/assets/partner/46.png'),
          require('@/assets/partner/47.png'),
          require('@/assets/partner/48.png')
        ],

        // SEO
        content: {
          url: '/',
          title: 'PT Solusi Digital Manunggal',
          description: 'PT Solusi Digital Manunggal adalah perusahaan yang berfokus pada penyediaan solusi sumber daya manusia. Dengan pengalaman yang luas dalam industri teknologi, amanata memiliki komitmen untuk membantu organisasi dalam mengelola aspek-aspek kunci terkait sumber daya manusia dengan lebih efektif dan efisien.',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {

    },
    watch: {
      
    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      company () {
        return this.$store.state.header.config.company
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r+35}, ${g+37}, ${b}, 0.97)`
      },
      set_color_rgb_soft () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.1)`
      }
    },
    mounted () {
      
    },
    methods: {
      onAfterSlideChange(index) {
        this.selected.price = index
      }
    }
  }
</script>