<template>
  <v-layout column>
    <div class="text-center">
      <section>
        <v-container 
          class="d-flex align-center"
          style="min-height: 100vh;">
          <v-row 
            justify="center" 
            align="center">
            <v-col>
              <v-card
                flat
                color="transparent"
                class="pa-4">
                <v-row 
                  justify="center" 
                  align="center">
                  <v-col>
                    <div>
                      <v-img 
                        contain
                        width="200"
                        height="200"
                        alt="amanata" 
                        :src="require('@/assets/image/404.png')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular 
                              indeterminate 
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </div>

                    <div>
                      <div class="my-16">
                        <div 
                          class="title font-weight-bold black--text mb-3">
                          Halaman Tidak Ditemukan
                        </div>

                        <div 
                          class="body-1 line-text-second"
                          style="opacity: 60%">
                          Sepertinya halaman yang Anda tuju tidak terdaftar dalam website kami.
                        </div>
                      </div>

                      <div
                        class="d-flex justify-center">
                        <v-btn
                          width="250"
                          large
                          rounded
                          outlined
                          :color="set_color"
                          class="body-1 font-weight-bold text-capitalize d-flex align-center mx-8"
                          :style="`color: ${set_color};`"
                          to="/home">
                          <v-icon>mdi-arrow-left-thick</v-icon>

                          <v-spacer />

                          <span class="ml-1">
                            kembali ke beranda
                          </span>
                        </v-btn>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </section>
    </div>
  </v-layout>
</template>

<script> 
  import { createSEOMeta } from '@/utils/seo'
  export default {
    name: 'error-404',
    data () {
      return {
        // SEO
        content: {
          url: '',
          title: '404',
          description: 'Halaman tidak ditemukan',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    props: {
      error: {
        type: Object,
        default: () => {},
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    computed: {
      set_color () {
        return this.$store.state.header.config.color
      }
    }
  }
</script>