<template>
  <v-app>
    <Header />

    <v-sheet 
      light
      style="background: #f9f9f9;">
      <Loader v-if="loader" />
      
      <nuxt v-else />
    </v-sheet>
    
    <Footer v-if="!loader" />

    <v-fab-transition>
      <v-btn
        v-scroll="onScroll"
        v-show="fab"
        elevation="3"
        fab
        dark
        fixed
        bottom
        right
        color="#444444"
        @click="$vuetify.goTo('#app', { duration: 500, offset: 0 })">
        <v-icon large>mdi-chevron-up</v-icon>
      </v-btn>
    </v-fab-transition>        
  </v-app>
</template>

<script>
  import Loader from "~/components/Loader.vue"

  import Header from "~/components/public/Header.vue"
  import Footer from "~/components/Footer.vue"
  
  export default {
    data () {
      return {
        loader: true,
        fab: false
      }
    },
    components: {
      Loader,
      Header,
      Footer
    },
    beforeCreate() {
      this.loader = true
    },
    created() {
      
    },
    mounted () {
      this.loader = false
    },
    methods: {
      onScroll (e) {
        if (typeof window === 'undefined') return

        const top = window.pageYOffset || e.target.scrollTop || 0

        this.fab = top > window.innerHeight-100
      }
    }
  }
</script>
